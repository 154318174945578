import React, { Suspense } from 'react';
import Head from 'next/head';
import MenuMain from '../components/HomeComponents/MenuMain';
import Footer from '../components/HomeComponents/Footer';
import { Layout } from 'antd';
import {i18n} from 'themes/modules/I18n'
import useBaseHook from "themes/hooks/BaseHooks";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const HomeLayout = (props: any) => {
  const { t, notify, redirect, router } = useBaseHook();
    const {metaData = {}, data = {} } = props
    return (<React.Fragment>
      <Head>
        <title>MQ ICT Solutions</title>
        <meta property="og:url" content={`${data.siteUrl}${metaData.url || ""}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="MQ ICT SOLUTIONS" />
        <meta property="og:description" name="description" content={t('pages:meta.title')} />
        <meta property="og:image" content="/static/img/img1.jpeg" />
        <link rel="icon" sizes="94x94" type="image/gif" href="/static/img/flaticon.png"></link>

      </Head>
      <div id="home">
        <main>
          <Layout>
            <MenuMain />
              <div className="content">
                {props.children}
              </div>
            <Footer />
          </Layout>
        </main>
      </div>
    </React.Fragment>
    );
  }


export default HomeLayout;