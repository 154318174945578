import React, { useState } from 'react';
import { Row, Col, Button, Form, Input, Divider } from 'antd';
import useBaseHook from "themes/hooks/BaseHooks";
import to from 'await-to-js'
import MailService from "themes/services/api/MailService"
import {i18n} from 'themes/modules/I18n'
import { FacebookProvider, CustomChat } from "react-facebook";
const { TextArea } = Input;
const Footer = () => {
    const { t, notify, redirect, router } = useBaseHook();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const mailService = new MailService()
    //submit form
    const onFinish = async (values: any): Promise<void> => {
        // console.log(i18n.language)
        values.lang = i18n.language || "vi"
        setLoading(true)
        let [error, result]: any[] = await to(mailService.send(values));
        setLoading(false)
        if (error) return notify(t(`errors:${error.code}`), '', 'error', error.code)
        form.resetFields()
        notify(t('pages:contact.successMail'), t('pages:contact.textSuccessMail'), 'success')
    }


    return <>
        <div id="footter-main-pro-mqn" className="block">
            <div className="container">
                <div className="title-contact-main-pro">
                    <div className="title">{t('pages:contact.title')}</div>
                    <img src="/static/img/lineWhite.jpeg" alt="hinhanh" />
                </div>
                <div className="form-contact-main-pro-mqn">
                    <Form
                        form={form}
                        name="createCompany"
                        onFinish={onFinish}
                        scrollToFirstError
                    >
                        <Row gutter={8}>
                            <Col xs={24} md={12} lg={12} xl={12}>
                                <Col span={24}>
                                    <Form.Item
                                        name="name"
                                        rules={[{ required: true, message: t('common:formContact.required') }]}
                                    >
                                        <Input placeholder={t('common:formContact.username')} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="company"
                                    >
                                        <Input placeholder={t('common:formContact.company')} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true, message: t('common:formContact.required') }]}
                                    >
                                        <Input placeholder={t('common:formContact.email')} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="phone"
                                        rules={[{ required: true, message: t('common:formContact.required') }]}
                                    >
                                        <Input placeholder={t('common:formContact.phone')} />
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col xs={24} md={12} lg={12} xl={12}>
                                <Col span={24}>
                                    <Form.Item
                                        name="message"
                                        rules={[{ required: true, message: t('common:formContact.required') }]}
                                    >
                                        <TextArea placeholder={t('common:formContact.messgaer')} rows={7} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={loading}>
                                            {t('common:Button.feedback')}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className="address-company-main-pro">
                    <Row gutter={12}>
                        <Col xs={24} md={12} lg={12} xl={6}>
                            <div className="item-address-pro">
                                <h1>{t('pages:contact.MQvn')}</h1>
                                <div className="item-company-adrress-mqn FixBottom">
                                    <img src="/static/img/map.jpeg" alt="hinhanh" />
                                    <p className="itemMapMobile">{t('pages:contact.addressMQvn')}</p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/mail.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent">{t('pages:contact.emailMQvn')}</p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/internet.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent"><a href="https://mqsolutions.vn/">{t('pages:contact.websiteMQvn')}</a></p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/phone.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent">{t('pages:contact.phoneMQvn')}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={6}>
                            <iframe
                                width="100%"
                                height="267"
                                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJUYBgrL-sNTERBrVWZIHZDU0&key=AIzaSyAgOuZWde1UFUOIunPgFFabrbsjPhKxTF0"
                            ></iframe>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={6}>
                            <div className="item-address-pro">
                                <h1>{t('pages:contact.MQjp')}</h1>
                                <div className="item-company-adrress-mqn FixBottom">
                                    <img src="/static/img/map.jpeg" alt="hinhanh" />
                                    <p>{t('pages:contact.addressMQjp')}</p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/mail.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent">{t('pages:contact.emailMQjp')}</p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/internet.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent"><a href="https://mqsolutions.co.jp">{t('pages:contact.websiteMQjp')}</a></p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/phone.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent">{t('pages:contact.phoneMQjp')}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={6}>
                            <iframe
                                width="100%"
                                height="267"
                                src="https://www.google.com/maps/embed/v1/place?q=1-10-4%20Chyuorinkan%2C%20Yamato%20city%20Kanagawa%20prefecture%20242-0007%20Japan&key=AIzaSyAgOuZWde1UFUOIunPgFFabrbsjPhKxTF0"
                            ></iframe>
                        </Col>
                        <Divider />
                        <Col xs={24} md={12} lg={12} xl={6}>
                            <div className="item-address-pro">
                                <h1>{t('pages:contact.MQsing')}</h1>
                                <div className="item-company-adrress-mqn FixBottom">
                                    <img src="/static/img/map.jpeg" alt="hinhanh" />
                                    <p className="itemMapMobile">{t('pages:contact.addressMQsing')}</p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/mail.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent">{t('pages:contact.emailMQsing')}</p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/internet.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent"><a href="https://mq-ict.com.sg">{t('pages:contact.websiteMQsing')}</a></p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/phone.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent">{t('pages:contact.phoneMQsing')}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={6}>
                            <iframe
                                width="100%"
                                height="267"
                                src="https://www.google.com/maps/embed/v1/place?q=30%20Cecil%20Street%20%2319-08%20Prudential%20Tower%2C%20Singapore%20(049712)&key=AIzaSyAgOuZWde1UFUOIunPgFFabrbsjPhKxTF0"
                            ></iframe>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={6}>
                            <div className="item-address-pro">
                                <h1>{t('pages:contact.MQno')}</h1>
                                <div className="item-company-adrress-mqn FixBottom">
                                    <img src="/static/img/map.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent">{t('pages:contact.addressMQno')}</p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/mail.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent">{t('pages:contact.emailMQno')}</p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/internet.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent"><a href="https://mqsolutions.no">{t('pages:contact.websiteMQno')}</a></p>
                                </div>
                                <div className="item-company-adrress-mqn">
                                    <img src="/static/img/phone.jpeg" alt="hinhanh" />
                                    <p className="fixTopContent">{t('pages:contact.phoneMQno')}</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} md={12} lg={12} xl={6}>
                            <iframe
                                width="100%"
                                height="267"
                                src="https://www.google.com/maps/embed/v1/place?q=Moltmyra%2019%207091%20Tiller%2C%20Na%20Uy&key=AIzaSyAgOuZWde1UFUOIunPgFFabrbsjPhKxTF0"
                            ></iframe>
                        </Col>
                    </Row>
                </div>
            </div>
            <FacebookProvider appId="1603871196465726" chatSupport>
                <CustomChat pageId="553253694713486" minimized={false} />
            </FacebookProvider>
        </div>
        <div className="copyRightMqn">
            <p>{t('pages:copyright.title')}</p>
        </div>
    </>
}


Footer.getInitialProps = async (ctx: any) => {
    return {
        namespacesRequired: ['pages', 'buttons', 'messages', 'errors']
    }
}

export default Footer    