import { useState, useEffect } from 'react'
import { Menu, Row, Col, Button, Select } from 'antd';
import { MenuFoldOutlined, } from '@ant-design/icons';
import MenuMobie from './MenuMobie';
import useScroll from 'themes/hooks/Scroll';
import useBaseHooks from 'themes/hooks/BaseHooks';
import { i18n } from 'themes/modules/I18n';
import { debounce } from 'lodash';
const { Option } = Select;

const getDimensions = (ele: any) => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom,
    };
};

const scrollTo = (ele: any) => {
    ele.scrollIntoView({
        behavior: "smooth",
        block: "start",
    });
};

const MenuMain = (props: any) => {
    const { t , redirect } = useBaseHooks({ lang: ['menu'] })
    const [visibleSidebar, setVisibleSidebar] = useState(false)
    const [headerClass, setHeaderClass] = useState("transparent")
    let position: any;

    const setPosition = async (_position: any) => {
        let menuItem: any = document.getElementsByClassName('ant-menu-item');
        [...menuItem].map((item: any) => {
            let _className = item.className;
            if(item.id === `menu-item-${_position}`) {
                item.className = [..._className.split(' '), 'ant-menu-item-selected'].join(' ')
            } else {
                item.className = _className.split(' ')
                    .filter((item: any) => item != 'ant-menu-item-selected')
                    .join(' ')
            }
        })
        position = _position;
    }

    useScroll(({ xOffset, yOffset }: any) => {
        const menuItem: any = document.getElementsByClassName('block');
        [...menuItem].map((item: any, index: number) => {
            let {offsetTop, height} = getDimensions(item);
            if(parseInt(yOffset + 100) >= parseInt(offsetTop) 
                && parseInt(yOffset + 100) <= parseInt(offsetTop + height) 
                && position != item.id
            ) {
                setPosition(item.id)
            }
        })

        if (yOffset == 0 && headerClass != "transparent") {
            setHeaderClass("transparent")
        }
        else if (headerClass != "whitebg") {
            setHeaderClass("whitebg")
        }
    })

    const onVisibleChange = (newState: boolean) => {
        setVisibleSidebar(newState)
    }

    return <>
        <div id="headerMain" style={{ background: 'none', height: '0px' }}>
            <div className="bodyContentHead">
                <div className={`menuPc ${headerClass}`} key={"pc"}>
                    <div className="container" key={"pc1"}>
                        <Row>
                            <Col md={21} lg={3} xl={4} key={"pc2"}>
                                <div className="logo-main">
                                    <a href="#home"><img src="/static/img/logo.jpeg" alt="hinhanh" /></a>
                                </div>
                            </Col>
                            <Col md={3} lg={21} xl={20}>
                                <Menu
                                    mode="horizontal"
                                    className="customerMenu navbar-items"
                                    defaultSelectedKeys={['1']}
                                    id="menu"
                                >
                                    <Menu.Item key="1" id="menu-item-home">
                                        <a href="#home">{t("frontend.home.home")}</a>
                                    </Menu.Item>
                                    <Menu.Item key="2" id="menu-item-about">
                                        <a href="#about">{t("frontend.home.about")}</a>
                                    </Menu.Item>
                                    <Menu.Item key="3" id="menu-item-services">
                                        <a href="#services">{t("frontend.home.service")}</a>
                                    </Menu.Item>
                                    <Menu.Item key="4" id="menu-item-product">
                                        <a href="#product">{t("frontend.home.product")}</a>
                                    </Menu.Item>
                                    <Menu.Item key="5" id="menu-item-customers">
                                        <a href="#customers">{t("frontend.home.testimonial")}</a>
                                    </Menu.Item>
                                    {/*<Menu.Item key="7" id="menu-item-news">
                                        <a href="#news">{t("frontend.home.news")}</a>
                                    </Menu.Item>*/}
                                    <Menu.Item key="8" id="menu-item-footter-main-pro-mqn">
                                        <a href="#footter-main-pro-mqn">{t("frontend.home.contact")}</a>
                                    </Menu.Item>
                                    <Menu.Item key="9" className="noneHover">
                                        <img src="/static/img/flg_vi.jpeg"  className="langActive" alt="hinhanh" onClick={() => {i18n.changeLanguage('vi'); redirect("https://mqsolutions.vn/?lang=vi");}} />
                                        <img src="/static/img/flg_en.jpeg"  className="langActive" alt="hinhanh" onClick={() => {i18n.changeLanguage('en'); redirect("https://mq-ict.com.sg/?lang=sg");}} />
                                        <img src="/static/img/flg_jp.jpeg"  className="langActive" alt="hinhanh" onClick={() => {i18n.changeLanguage('ja'); redirect("https://mqsolutions.co.jp/?lang=ja");}} />
                                    </Menu.Item>
                                </Menu>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* Back  mobie */}
                <div className="backgroundMneuMobie">
                    <MenuMobie visible={visibleSidebar} onVisibleChange={onVisibleChange} />
                    <Row>
                        <Col xs={8}>
                            <Button onClick={() => onVisibleChange(!visibleSidebar)} ><MenuFoldOutlined /></Button>
                        </Col>
                        <Col xs={8}>
                            <div className="logo">
                                <img src="/static/img/logo.jpeg" alt="hinhanh" />
                            </div>
                        </Col>
                        <Col xs={8}>
                            <div className="lang-mobile">
                                <Select defaultValue={i18n.language} style={{ width: 75 }} key="1">
                                    <Option key="2" value="vi"><img src="/static/img/flg_vi.jpeg" className="langActive" alt="hinhanh" onClick={() => {i18n.changeLanguage('vi'); redirect("https://mqsolutions.vn");}} /></Option>
                                    <Option key="3" value="en"><img src="/static/img/flg_en.jpeg" className="langActive" alt="hinhanh" onClick={() => {i18n.changeLanguage('en'); redirect("https://mq-ict.com.sg");}} /></Option>
                                    <Option key="4" value="ja"><img src="/static/img/flg_jp.jpeg" className="langActive" alt="hinhanh" onClick={() => {i18n.changeLanguage('ja'); redirect("https://mqsolutions.co.jp");}} /></Option>
                                </Select>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* End menu mobie */}
            </div>
        </div>
    </>
}

export default MenuMain
