import { Menu, Drawer } from 'antd';
import useBaseHooks from 'themes/hooks/BaseHooks';

const MenuMobie = (props: any) => {
  const { t } = useBaseHooks({ lang: ['menu'] })

  return <>
    <div className="MenuMobie">
      <Drawer
        visible={props.visible}
        placement="left"
        onClose={() => props.onVisibleChange(false)}
        maskClosable
        closable={false}
        destroyOnClose={true}
        bodyStyle={{
          padding: 0
        }}
      >

      <div className="logo" />
        <Menu 
          theme="dark" 
          mode="inline" 
          defaultSelectedKeys={['home']}
        >
          <Menu.Item key="home">
            <a href="#home" onClick={() => props.onVisibleChange(false)}>{t("frontend.home.home")}</a>
          </Menu.Item>
          <Menu.Item key="about">
            <a href="#about" onClick={() => props.onVisibleChange(false)}>{t("frontend.home.about")}</a>
          </Menu.Item>
          <Menu.Item key="services">
            <a href="#services" onClick={() => props.onVisibleChange(false)}>{t("frontend.home.service")}</a>
          </Menu.Item>
          <Menu.Item key="product">
            <a href="#product" onClick={() => props.onVisibleChange(false)}>{t("frontend.home.product")}</a>
          </Menu.Item>
          <Menu.Item key="customers">
            <a href="#customers" onClick={() => props.onVisibleChange(false)}>{t("frontend.home.testimonial")}</a>
          </Menu.Item>
          {/*<Menu.Item key="news">
            <a href="#news" onClick={() => props.onVisibleChange(false)}>{t("frontend.home.news")}</a>
          </Menu.Item>*/}
          <Menu.Item key="footter">
            <a href="#footter-main-pro-mqn" onClick={() => props.onVisibleChange(false)}>{t("frontend.home.contact")}</a>
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  </>
}

export default MenuMobie

