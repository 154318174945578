import BaseApi from './BaseService'

class MailService extends BaseApi {
  constructor(context?: Context) {
    super(context)
    this.name = 'mail'; //kế thừa CRUD từ thằng cha
  }

  async send(data:any) {
    console.log("before send", data)

    const url = this.route(`api.${this.name}.send`,data).href
    return await this.Request.post(url, data)
  }
}

export default MailService;
